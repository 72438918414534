import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/company-type",
    method: "Get",
    params: params,
  });
}

// 增加
export function addCompanyType(requestBody) {
  return request({
    url: "/company-type",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editCompanyType(requestBody) {
  return request({
    url: "/company-type",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deleteCompanyType(params) {
  return request({
    url: "/company-type",
    method: "Delete",
    params: params,
  });
}

// 获取companyType option
export function getCompanyTypeOptions() {
  return request({
    url: "/company-type/option",
    method: "Get",
  });
}
