import request from "@/utils/request";

export function exportAreaInfo() {
  return request({
    url: "/excel-export/area-info",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportCompanyInfo() {
  return request({
    url: "/excel-export/company-info",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportCompanyType() {
  return request({
    url: "/excel-export/company-type",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportCompanyLevel() {
  return request({
    url: "/excel-export/company-level",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportMonitoryPointInfo() {
  return request({
    url: "/excel-export/monitory-point-info",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportMonitoryPointType() {
  return request({
    url: "/excel-export/monitory-point-type",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportPollutionInfo() {
  return request({
    url: "/excel-export/pollution-info",
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportDatagramLog(params) {
  return request({
    url: "/excel-export/datagram-log",
    params: params,
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportStatistic(requestBody) {
  return request({
    url: "/excel-export/statistic",
    data: requestBody,
    method: "Post",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportStatisticMonth(params) {
  return request({
    url: "/excel-export/statistic-month",
    params: params,
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportReal(requestBody) {
  return request({
    url: "/excel-export/real",
    data: requestBody,
    method: "Post",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportCheck(requestBody) {
  return request({
    url: "/excel-export/check",
    data: requestBody,
    method: "Post",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportAlert(requestBody) {
  return request({
    url: "/excel-export/alert",
    data: requestBody,
    method: "Post",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}

export function exportCheckDetail(params) {
  return request({
    url: "/excel-export/check-detail",
    params: params,
    method: "Get",
    responseType: "arraybuffer",
  }).then((result) => {
    const blob = new Blob([result.data], { type: "application/octet-stream" });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = href;
    link.download =
      decodeURI(
        result.headers["content-disposition"]
          .replace("attachment;filename*=utf-8''", "")
          .replace(".xlsx", "")
      ) + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link);
  });
}
