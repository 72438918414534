import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/real",
    method: "Get",
    params: params,
  });
}

// 在地图上渲染监控点
export function getMapMaker() {
  return request({
    url: "/real/map",
    method: "Get",
  });
}
